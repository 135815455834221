import dayjs from 'dayjs';
import { isoCountries } from './libCountry';

const { VUE_APP_LANGUAGES_LIST } = process.env;
const languageList = JSON.parse(VUE_APP_LANGUAGES_LIST);

const gMixin = {
  methods: {
    groupByKey(array, key, moreKey) {
      return array
        .reduce((hash, obj) => {
          if (obj[key] === undefined) return hash;
          if (moreKey !== undefined) {
            const newKey = `${obj[key]}_${obj[moreKey]}`;
            return Object.assign(hash, { [newKey]: (hash[newKey] || []).concat(obj) });
          } else {
            return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
          }
        }, {});
    },
    addDays(fromDate, days) {
      const date = new Date(fromDate);
      date.setDate(date.getDate() + days);
      return date;
    },
    dateFormat(date) {
      return new Date(date).toISOString().split('T')[0];
    },
    timeOffset() {
      return new Date().getTime() - this.convertTZ(new Date(), 'Asia/Jerusalem').getTime();
    },
    convertTZ(date, tzString) {
      return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString }));
    },
    convertCountryNameToISOCode(pCountryName) {
      if (isoCountries[pCountryName] !== undefined) {
        return isoCountries[pCountryName];
      } else {
        return pCountryName;
      }
    },
    convertCurrencySymbol(pCurrency) {
      let symbol = '';
      if (pCurrency.toUpperCase() === 'US' || pCurrency.toUpperCase() === 'USD' || pCurrency === '$') {
        symbol = '$';
      } else if (pCurrency.toUpperCase() === 'EU' || pCurrency.toUpperCase() === 'EUR' || pCurrency === '€') {
        symbol = '€';
      } else if (pCurrency.toUpperCase() === 'IS' || pCurrency.toUpperCase() === 'NIS' || pCurrency.toUpperCase() === '₪') {
        symbol = '₪';
      } else {
        symbol = pCurrency;
      }
      // symbol = '€';
      return symbol;
    },
    getPriceWithSymbol(pCurrency, pPrice) {
      return `${this.convertCurrencySymbol(pCurrency || 'US')}${pPrice}`;
    },
    convertCurrencyCode(pCurrency) {
      let code = '';
      if (pCurrency.toUpperCase() === 'US' || pCurrency.toUpperCase() === 'USD' || pCurrency === '$') {
        code = 'USD';
      } else if (pCurrency.toUpperCase() === 'EU' || pCurrency.toUpperCase() === 'EUR' || pCurrency === '€') {
        code = 'EUR';
      } else if (pCurrency.toUpperCase() === 'IS' || pCurrency.toUpperCase() === 'NIS' || pCurrency.toUpperCase() === '₪') {
        code = 'ILS';
      } else {
        code = pCurrency;
      }
      return code;
    },
    capitalizeFirstLetter(str) {
      // converting first letter to uppercase
      const capitalized = str.replace(/^./, str[0].toUpperCase());
      return capitalized;
    },
    getMarkLanguage(pLang, pLength) {
      if (pLength === 2) {
        return this.capitalizeFirstLetter(pLang);
      } else if (pLength === 3) {
        return this.capitalizeFirstLetter(languageList[pLang].toLowerCase());
      } else {
        return pLang;
      }
    },
    getCheckInOut() {
      const checkIn = dayjs(this.product.flights[0].FlightDetail[0].FL_Date).add(Number(this.product.hotel_shift_1), 'day'),
        checkOut = checkIn.add(Number(this.product.duration_1), 'day');
      return `${checkIn.format('DD-MM-YYYY')} - ${checkOut.format('DD-MM-YYYY')}`;
    },
    splitNumber(total, count) {
      const splitCount = Math.round(total / count);
      let deductionValue = total;
      const result = [];

      for (let i = 0; i < count; i += 1) {
        if (deductionValue <= 0) break;

        const splitValue = Math.min(splitCount, deductionValue);
        result.push(splitValue);
        deductionValue -= splitValue;
      }

      return result.reverse();
    },
  },
};

export default gMixin;
